* {
  box-sizing: border-box;
  font-family: "Playfair Display SC", serif;
}

body {
  background-color: black;
}

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  font-size: 18px;
}

.sign-in-link {
  color: white;
  margin-top: 1rem;
}

.sign-in-link:hover {
  cursor: pointer;
}

.circle {
  position: absolute;
  border: solid 1px #ccc;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  pointer-events: none;
}

.ggv-heading {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.circle .sign-in-link:hover {
  cursor: pointer;
}

.neonText {
  color: #fff;
  text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #0fa,
    0 0 82px #0fa, 0 0 92px #0fa, 0 0 102px #0fa, 0 0 151px #0fa;
}

/* Presentational Styles */
