/* Base styles for form elements */
form label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

form input[type="text"] {
  display: block;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
  font-size: 1rem;
}

form input[type="number"] {
  display: block;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
  font-size: 1rem;
}

form textarea {
  display: block;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
  font-size: 1rem;
}

form select {
  display: block;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
  font-size: 1rem;
}

form button[type="submit"] {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 1rem;
}

form button[type="submit"]:hover {
  cursor: pointer;
  background-color: #555;
}

/* Media query for small screens */
@media screen and (max-width: 768px) {
  form {
    max-width: 90%;
    margin: auto;
  }
}

/* Media query for medium screens */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  form {
    max-width: 80%;
    margin: auto;
  }
}

/* Media query for large screens */
@media screen and (min-width: 1024px) {
  form {
    max-width: 60%;
    margin: auto;
  }
}

/* Styles for form container */
form {
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
}

h2 {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
}
