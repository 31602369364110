.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  position: sticky;
  top: 0;
  z-index: 999;
}

.Header__brand {
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

.Header__account {
  display: flex;
  align-items: center;
}

.Header__account-icon {
  margin-right: 10px;
  color: #333;
  font-size: 20px;
}

.Header__account-name {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

@media (max-width: 768px) {
  .Header {
    flex-direction: column;
  }

  .Header__account {
    margin-top: 10px;
  }

  .Header__account-name {
    font-size: 14px;
  }
}
