.form-div {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sign-in-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sign-in-btn {
  display: flex;
  margin-top: 1.4rem;
  color: black;
  font-size: 1.4rem;
}

.form-div {
  height: 100vh;
  color: black;
  text-align: center;
}

.input-field {
  margin: 0.5rem 0;
  width: 25vmax;
  color: black;
  outline: none;
}

.form-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.sign-in-h2 {
  font-size: 2rem;
}

.sign-in-h3 {
  font-size: 1.4rem;
  margin-bottom: 1.4rem;
}
